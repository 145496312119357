<template>
  <AccountTenantsContext
    :is-auth="isAuthenticated && !!idTokenClaims"
    :is-fullscreen="isFullScreen"
    v-slot="{ isTenant, isOnTenantRoute }"
  >
    <div
      v-if="isAuthenticated && !hasError"
      class="duration-500"
      :class="v2LayoutEnabled ? '' : 'max-w-7xl mx-auto'"
    >
      <div
        class="flex"
        :class="{
          'h-[calc(100vh-3rem)] sm:h-[calc(100vh-3.5rem)]': v2LayoutEnabled && isTenant,
          'h-screen': v2LayoutEnabled && !isTenant,
          'min-h-screen': !v2LayoutEnabled,
        }"
      >
        <SideNavigation v-if="!isFullScreen" :class="isTenant ? 'sm:pt-12' : ''" />

        <!-- Main content -->
        <ResizableContext
          :is-auth="isAuthenticated && !!idTokenClaims"
          :is-tenant="isTenant"
          :is-on-tenant-route="isOnTenantRoute"
          :is-fullscreen="isFullScreen"
          class=""
          :class="isFullScreen ? '' : 'sm:pl-16 md:pl-24'"
        >
          <main
            :class="
              route.meta.disableWrapperClasses ? '' : 'px-2 sm:px-4 md:px-6 pb-6 sm:pb-0'
            "
            class="grow overflow-hidden duration-300 min-h-full flex flex-col"
          >
            <div
              :class="
                route.meta.disableWrapperClasses
                  ? ''
                  : v2LayoutEnabled
                    ? ''
                    : 'max-w-[1072px] mx-auto'
              "
              class="grow w-full h-full flex flex-col duration-300"
            >
              <div
                :class="{
                  'fixed top-0 sm:left-16 md:left-24 left-0 z-50 w-screen sm:w-[calc(100vw-3.5rem)] md:w-[calc(100%-95px)] px-2 sm:px-4 md:px-6 bg-white dark:bg-slate-900 border-b border-slate-200 dark:border-slate-700/40':
                    v2LayoutEnabled && !isTenant,
                }"
              >
                <Header
                  @sign-out="doLogout"
                  v-if="!isFullScreen && !route.meta.hideHeader"
                />
              </div>
              <AppBannerWrapper
                v-if="!isFullScreen && !route.meta.hideBanners"
                :class="{
                  'pt-20': v2LayoutEnabled && !isTenant,
                  'empty:hidden': v2LayoutEnabled && isTenant,
                }"
              />
              <!-- <Hero /> -->

              <!-- Content -->
              <div
                class="grow md:flex space-y-8 md:space-y-0 md:space-x-8"
                :class="{
                  '': route.meta.disableWrapperClasses,
                  'pb-16 md:pb-20': !v2LayoutEnabled && !route.meta.disableWrapperClasses,
                  'sm:pb-2 min-h-full': v2LayoutEnabled,
                  'min-h-[80vh]': !v2LayoutEnabled,
                }"
              >
                <slot></slot>

                <!-- Render-less -->
                <SocketContext v-if="footerReady && !isLocal" />
              </div>
              <!-- allows page to scroll to bottom on visit -->
              <span id="v2-layout-bottom" class="block h-1 w-full"></span>

              <Footer
                :class="
                  route.meta.hideFooter || v2LayoutEnabled
                    ? 'fixed top-full opacity-0 pointer-events-none'
                    : ''
                "
                @ready="footerReady = true"
              />
            </div>
          </main>
        </ResizableContext>
      </div>

      <TestLayer> </TestLayer>
    </div>
    <!-- no-auth routes -->
    <slot v-else></slot>

    <HelpSidebar />
  </AccountTenantsContext>
</template>
<script setup lang="ts">
import Bugsnag from "@bugsnag/js";
import SideNavigation from "../partials/SideNavigation.vue";
import Header from "../partials/Header.vue";
import Footer from "../partials/Footer.vue";
import SocketContext from "./SocketContext.vue";
import TestLayer from "./TestLayer.vue";

import AppBannerWrapper from "@/components/shared/AppBannerWrapper.vue";
import AccountTenantsContext from "./AccountTenantsContext.vue";
import ResizableContext from "./ResizableContext.vue";
import HelpSidebar from "@/components/help/HelpSidebar.vue";
const router = useRouter();
const route = useRoute();
const { t } = withI18nHelpers();
const billingStore = useBillingStore();
const { v2LayoutEnabled } = useV2Layout();
// socketContext tries to teleport to footer sometimes before it's in the DOM
const footerReady = ref(false);
const hasError = ref(false);
const { loadingAuthentication, isLoadingAccount, isAuthenticated, idTokenClaims, user } =
  useCurrentUser();
const { data: subscription } = useSubscription(
  computed(() => !!currentSessionInformation.appAuthToken)
);
const { maybeShowBanners } = useBillingBanners();
const tourStore = useTourStore();
// const ready = ref(false);
const isLocal = import.meta.env.MODE === "local-test";

const isFullScreen = computed(
  () =>
    router.currentRoute.value.path.startsWith("/tenant") ||
    router.currentRoute.value.name === RouteNames.ONBOARDING ||
    router.currentRoute.value.name === RouteNames.WELCOME ||
    router.currentRoute.value.name === RouteNames.PAYMENT_FAILED ||
    router.currentRoute.value.name === RouteNames.THIRD_PARTY ||
    (!hasFeatureFlag("freeTrialNoStripe") &&
      router.currentRoute.value.name === RouteNames.PLANS)
);
async function doLogout() {
  await router.push({ name: RouteNames.LOGOUT });
}
const authLoadingState = computed(() => {
  const state = {
    shown: false,
    message: undefined as string | undefined,
  };
  if (loadingAuthentication.value || currentSessionInformation.initializingSession) {
    state.shown = true;
    state.message = t("auth.signInWaitingMessage");
  } else if (
    (router.currentRoute.value.name === RouteNames.WELCOME ||
      router.currentRoute.value.name === RouteNames.TENANT_WELCOME) &&
    !!router.currentRoute.value.query.checkoutSessionId
  ) {
    state.shown = true;
    state.message = t("subscriptionContext.finishingMessage");
  } else if (router.currentRoute.value.name === RouteNames.LOGOUT) {
    return {
      shown: true,
      message: t("authWordsSignOutDescription"),
    };
  }
  return state;
});

watch(subscription, v => {
  if (v) {
    billingStore.setSubscription(v);
    maybeShowBanners();
  }
});

watch(appAuthToken, async v => {
  if (v && import.meta.env.MODE === "local-test") {
    const response = await useGetRequest<Record<string, any>>("/get-test-claims", {
      baseURL: "http://localhost:3001",
      headers: {
        Authorization: `Bearer ${appAuthToken.value}`,
      },
    });
    const claims = response.data;
    if (claims) setCurrentSessionClaims(claims);
  }
});

watch(user, currentVal => {
  if (!currentVal) return;
  tourStore.setTourAccountData(currentVal?.welcomeTour);
  currentSessionInformation.preferredLanguage = currentVal.locale || "en";
  if (Bugsnag.isStarted()) {
    Bugsnag.setUser(currentVal.id, currentVal.name, currentVal.email);
    Bugsnag.addMetadata("user", {
      tenantId: currentVal.tenantId,
      activeAccountId: currentVal.activeAccountId,
    });
  }
});
defineExpose({ authLoadingState });
</script>

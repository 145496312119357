import type { ISuggestion } from "@/types/suggestion";

export function useAssistantSuggestionsRequest(
  category?: globalThis.Ref<string>,
  rated?: globalThis.Ref<boolean>,
  disabled?: globalThis.Ref<boolean>
) {
  const params = computed(() => {
    return {
      ...(category?.value ? { category: category.value } : {}),
      ...(rated?.value ? { rated: true } : {}),
    };
  });
  const enabled = computed(() => !disabled?.value);
  const query = useInfiniteQuery({
    queryKey: ["assistant-suggestions", params],
    queryFn: async ({ pageParam }: { pageParam?: string } = {}) => {
      const response = await useGetRequest<{ items: ISuggestion[]; nextLink?: string }>(
        "/account/idea",
        {
          params: {
            ...params.value,
            nextQuery: pageParam ? pageParam : undefined,
          },
        }
      );
      return response?.data || null;
    },
    initialPageParam: "",
    staleTime: 3 * MINUTE,
    enabled,
    getNextPageParam: lastPage => lastPage.nextLink || null,
    select: data => {
      return (
        data?.pages?.map((p: { items: ISuggestion[] }) => p.items)?.flat() || []
      ).map(d => ({
        ...d,
        tagMap: d.tags.reduce(
          (acc, tag) => ({ ...acc, [tag]: true }),
          {} as Record<string, boolean>
        ),
      }));
    },
  });
  return query;
}

import type { RouteRecordRaw } from "vue-router";
import { beforeEnterFeatureFlagged } from "./middleware/beforeEnterFeatureFlagged";

const { SETTING, INTEGRATIONS, CONNECTED_APPS, ACCOUNT_USERS, BILLING, NOTIFICATIONS } =
  RouteNames;
export const settingsRoutes: RouteRecordRaw[] = [
  {
    name: SETTING,
    path: "",
    component: () => import("@/partials/settings/AccountPanel.vue"),
    meta: {
      titleTag: "Settings | Marketing Assistant",
    },
  },

  {
    name: CONNECTED_APPS,
    path: "apps",
    component: () => import("@/partials/settings/AppsPanel.vue"),
    meta: {
      titleTag: "Settings | Marketing Assistant",

      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: INTEGRATIONS,
    path: "integrations",
    component: () => import("@/partials/settings/IntegrationsPanel.vue"),
    meta: {
      titleTag: "Integrations",
    },
  },
  {
    name: ACCOUNT_USERS,
    path: "users",
    component: () => import("@/partials/settings/AccountUsers.vue"),
    beforeEnter: [beforeEnterFeatureFlagged("multiUser")],
    meta: {
      titleTag: "Account Users | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: NOTIFICATIONS,
    path: "notifications",
    component: () => import("@/partials/settings/NotificationsPanel.vue"),
    meta: {
      titleTag: "Notifications | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: BILLING,
    path: "billing",
    component: () => import("@/partials/settings/BillingPanel.vue"),
    meta: {
      titleTag: "Plan & Billing | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
];

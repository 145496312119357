import type { RouteRecordRaw } from "vue-router";

const { SCHEDULED_ACTIVITIES, SINGLE_SCHEDULED_ACTIVITY } = RouteNames;
export const scheduledRoutes: RouteRecordRaw[] = [
  {
    name: SCHEDULED_ACTIVITIES,
    path: "",
    component: () => import("../views/ScheduledActivities.vue"),
  },
  {
    path: ":id",
    name: SINGLE_SCHEDULED_ACTIVITY,
    component: () => import("@/views/SingleTaskScheduled.vue"),
    meta: {
      titleTag: "Scheduled Activity | Marketing Assistant",
    },
  },
];

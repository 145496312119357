export type HelpDataBlock =
  | {
      element: "heading" | "text";
      innerText: string;
      inline?: boolean;
    }
  | {
      element: "list";
      items: string[];
      inline?: boolean;
    }
  | {
      element: "link";
      name: string | symbol; //use name
      params?: Record<string, string>;
      query?: Record<string, string>;
      innerText: string;
      inline?: boolean;
    }
  | {
      element: "help";
      key: HelpDataKey;
      innerText: string;
      inline?: boolean;
    };

export type HelpDataKey =
  | "none"
  | "assistant/website"
  | "assistant/competitors"
  | "assistant/social"
  | "assistant/files"
  | "assistant/tuning"
  | "assistant/research"
  | "settings/integration"
  | "settings/users"
  | "settings/usage"
  | "settings/billing"
  | "tasks/recent"
  | "tasks/scheduled"
  | "tasks/create"
  | "tasks/recent-single"
  | "tasks/scheduled-single"
  | "onboarding/company-and-website/suggestions"
  | "onboarding/company-and-website/no-suggestions"
  | "onboarding/company-description-initial/suggestions"
  | "onboarding/company-description-initial/no-suggestions"
  | "onboarding/company-social-site-links/suggestions"
  | "onboarding/company-social-site-links/no-suggestions"
  | "onboarding/competitor-websites/suggestions"
  | "onboarding/competitor-websites/no-suggestions";

export const helpData: Record<HelpDataKey, HelpDataBlock[]> = {
  none: [
    {
      element: "heading",
      innerText: "Help",
    },
    {
      element: "text",
      innerText:
        "Look out for the help button (?) in various places in the app to find useful information.",
    },
  ],
  "assistant/website": [
    {
      element: "heading",
      innerText: "My Company",
    },
    {
      element: "text",
      innerText:
        "Your website is your online presence. Our cutting edge model will automatically visit your website at set intervals and we use that data to improve your marketing assistant. We will also use this data to generate helpful suggestions to help you improve your website.",
    },
    {
      element: "heading",
      innerText: "Frequency of Visits",
    },
    {
      element: "text",
      innerText:
        "The more frequently we visit your website, the more accurate our model becomes. This in turn ensures your marketing assistant is always up to date with new changes",
    },
    {
      element: "list",
      items: [
        "On the free plan, your website is visited once every month.",
        "On the basic plan, your website is visited once a week",
        "On the premium and business plan, your website is visited daily",
      ],
    },
    {
      element: "heading",
      innerText: "Pages Processed",
    },
    {
      element: "text",
      innerText:
        "The number of pages processed is a great way to improve the accuracy of your assistant.",
    },
    {
      element: "list",
      items: [
        "On the free plan, the number of pages processed is 10.",
        "On the basic plan, the number of pages processed is 100.",
        "On the premium and business plan, the number of pages processed is 1000",
      ],
    },
  ],
  "assistant/competitors": [
    {
      element: "heading",
      innerText: "Competitors",
    },
    {
      element: "text",
      innerText:
        "If you have competitors on the internet, you can add their websites here. Your assistant will automatically visit these websites at set intervals and use that data to generate helpful suggestions to help you improve your website and marketing tasks.",
    },
    {
      element: "heading",
      innerText: "Frequency of Visits",
    },
    {
      element: "text",
      innerText:
        "The more frequently we visit your competitors, the more accurate our model becomes. This in turn ensures your marketing assistant is always up to date with new changes on your competitors' websites.",
    },
    {
      element: "list",
      items: [
        "On the free plan, your competitors are visited once every month.",
        "On the basic plan, your competitors are visited once a week",
        "On the premium and basic plan, your competitors are visited daily",
      ],
    },
    {
      element: "heading",
      innerText: "Pages Processed",
    },
    {
      element: "text",
      innerText:
        "The number of pages processed on competitor websites is a great way to improve the accuracy of your assistant.",
    },
    {
      element: "list",
      items: [
        "On the free plan, the number of pages processed is 10.",
        "On the basic plan, the number of pages processed is 100.",
        "On the premium and basic plan, the number of pages processed is 1000",
      ],
    },
    {
      element: "heading",
      innerText: "Social Accounts Processed",
    },
    {
      element: "text",
      innerText:
        "Our model also processes your competitors' social accounts and posts. These are also used to generate helpful suggestions to improve your social accounts and posts.",
    },
    {
      element: "list",
      items: [
        "On the free plan, the number of social accounts processed is 50.",
        "On the basic plan, the number of social accounts processed is 500.",
        "On the premium and basic plan, the number of social accounts processed is 5000",
      ],
    },
  ],
  "assistant/social": [
    {
      element: "heading",
      innerText: "Social Media",
    },
    {
      element: "text",
      innerText:
        "Social media is one of the most powerful tools for reaching out to your customers. Our model will automatically visit your social media accounts at set intervals and use that data to generate helpful suggestions to help you improve your subsequent social media posts.",
    },
    {
      element: "heading",
      innerText: "Frequency of Visits",
    },
    {
      element: "text",
      innerText:
        "The more frequently your social media accounts are visited, the more accurate our model becomes.",
    },
    {
      element: "list",
      items: [
        "On the free plan, your social media accounts are visited once every month.",
        "On the basic plan, your social media accounts are visited once a week",
        "On the premium and basic plan, your social media accounts are visited daily",
      ],
    },
    {
      element: "heading",
      innerText: "Posts Processed",
    },
    {
      element: "text",
      innerText: "The more posts are processed, the more accurate our model becomes.",
    },
    {
      element: "list",
      items: [
        "On the free plan, the number of posts processed is 50.",
        "On the basic plan, the number of posts processed is 500.",
        "On the premium and basic plan, the number of posts processed is 5000",
      ],
    },
  ],
  "assistant/files": [
    {
      element: "heading",
      innerText: "Files",
    },
    {
      element: "text",
      innerText:
        "If you have any other information about your company, which can not be found on your website or social media accounts, you can add it here.",
    },
    {
      element: "text",
      innerText:
        'These could be documents (PDF, Word, Excel, etc) or images (JPG, PNG, etc). You can, for example, upload your annual calendar and unlock possibilities such as generating event announcements for your "event in MAY" instead of having to manually specify the date and event name during task creation.',
    },
  ],
  "assistant/tuning": [
    {
      element: "heading",
      innerText: "Fine Tuning",
    },
    {
      element: "text",
      innerText: "Adjust the behavior of your assistant, to match our business needs.",
    },
    {
      element: "text",
      innerText:
        'Need to start over? You can always restore the default behavior by hitting the "Reset" and saving the default values.',
    },
  ],
  "assistant/research": [
    {
      element: "heading",
      innerText: "Research",
    },
    {
      element: "text",
      innerText:
        "Use queries to identify how your company performs on search engines. All queries are automatically prefixed with your company name, so it is best practice to omit the company name from your queries.",
    },
    {
      element: "heading",
      innerText: "Result Categories",
    },
    {
      element: "text",
      innerText: "The research results are broken down into 3 different categories.",
    },
    {
      element: "list",
      items: [
        "Company rank: The overall ranking of your company on the internet, as well as number of times it appears on search engines.",
        "Competitor rank: The ranking of your competitors on the internet, as well as number of times they appear on search engines.",
        "Favorable results: This will show you the number of results that improve your overall ranking, and those that don't. It also explains the reason for classifying the result as favorable or unfavorable.",
      ],
    },
    {
      element: "heading",
      innerText: "Limits",
    },
    {
      element: "text",
      innerText:
        "The more topics you research about your website, the more exposure your website gets, as we identify SEO pain points for you.",
    },
    {
      element: "list",
      items: [
        "On the free plan, you can research up to 2 topics.",
        "On the basic plan, you can research up to 5 topics.",
        "On the premium and business plans, you can research up to 15 topics per account.",
      ],
    },
  ],
  "settings/integration": [
    {
      element: "heading",
      innerText: "Integrations",
    },
    {
      element: "text",
      innerText: "Extend your assistant by integrating with your favorite tools.",
    },
    {
      element: "text",
      innerText:
        "Use the Email integration to send task generation commands to your assistant through email, which will in turn respond to your emails with the generated tasks.",
    },
    {
      element: "text",
      innerText:
        "Use the Facebook and Instagram integration, to send generated posts directly to your social media account from this app",
    },
    {
      element: "text",
      innerText:
        "Use the microsoft integration to send generated posts to Teams from this app",
    },
    {
      element: "text",
      innerText:
        "Use the WordPress integration to send generated articles to your WordPress site.",
    },
    {
      element: "text",
      innerText: "Use the Zapier integration for more complex automation.",
    },
  ],
  "settings/users": [
    {
      element: "heading",
      innerText: "Users",
    },
    {
      element: "text",
      innerText:
        "You can invite new users to your account. These users can use the assistant to perform tasks on ",
    },
  ],
  "settings/usage": [
    {
      element: "heading",
      innerText: "Usage",
    },
    {
      element: "text",
      innerText:
        "At the beginning of every billing cycle, your credits will be reset. These credits are used when you create tasks.",
    },
    {
      element: "text",
      innerText:
        "Features such as Research, Social, Website And Competitor Account processing, and Suggestions don't consume credits.",
    },
    {
      element: "text",
      innerText: "Each plan comes with a number of credits for use in your account.",
    },
    {
      element: "list",
      items: [
        "Free plan: 80 credits",
        "Basic plan: 200 credits",
        "Premium plan: 400 credits",
        "Business plan: 400 credits per account",
      ],
    },
  ],
  "settings/billing": [
    {
      element: "heading",
      innerText: "Billing",
    },
    {
      element: "text",
      innerText:
        "You can upgrade or downgrade your plan at any time on the payment portal. You can also change your payment method or cancel your subscription from the portal.",
    },
  ],
  "tasks/recent": [
    {
      element: "heading",
      innerText: "Recent Activities",
    },
    {
      element: "text",
      innerText:
        "You can find your recent tasks here. Tasks that have been set up to run on a future date will show up in ",
      inline: true,
    },
    {
      element: "link",
      innerText: "scheduled tasks",
      inline: true,
      name: RouteNames.SCHEDULED_ACTIVITIES,
    },
  ],
  "tasks/scheduled": [
    {
      element: "heading",
      innerText: "Scheduled Tasks",
    },
    {
      element: "text",
      innerText:
        "Scheduled activities take your content strategy to the next level. Want to create a scheduled activity that generates a christmas message every 24th December? We got you covered.",
    },
    {
      element: "text",
      innerText:
        'When creating a new task, you can always switch between the "immediate" and "scheduled" based on your needs.',
    },
    {
      element: "text",
      innerText: "After a task is done running, it can be found in ",
      inline: true,
    },
    {
      element: "link",
      innerText: "recent tasks",
      name: RouteNames.TASK_LIST,
      inline: true,
      params: {
        status: "recent",
      },
    },
  ],
  "tasks/recent-single": [
    {
      element: "heading",
      innerText: "Task Results",
    },
    {
      element: "text",
      innerText:
        "Pro tip! You can click the text in most tasks to copy it over to your clipboard.",
    },
    {
      element: "text",
      innerText:
        "You can leave feedback on the results of a task by clicking the thumbs-up or thumbs-down icons at the end. This will help us improve your assistant.",
    },
    {
      element: "text",
      innerText:
        "You can post some tasks directly to Facebook, Twitter, Wordpress, or Instagram. Make sure you have the corresponding ",
      inline: true,
    },
    {
      element: "link",
      name: RouteNames.INTEGRATIONS,
      innerText: " integration",
      inline: true,
    },
    {
      element: "text",
      innerText: " enabled for the best experience.",
      inline: true,
    },
    {
      element: "text",
      innerText:
        "If you want to regenerate a task, you can do that from the feedback section at the end of the results, by clicking the last icon after the thumbs-up and thumbs-down.",
    },
  ],
  "tasks/create": [
    {
      element: "heading",
      innerText: "Create or regenerate task",
    },
    {
      element: "text",
      innerText:
        "Don't want to type everything? Drag and drop an image, add a little some text for context, and let your assistant do the rest task for you.",
    },
    {
      element: "text",
      innerText: "When regenerating a task, you can always edit the task prompt.",
    },
    {
      element: "text",
      innerText:
        "You can also switch between immediate and scheduled tasks whenever required.",
    },
  ],
  "tasks/scheduled-single": [
    {
      element: "heading",
      innerText: "Scheduled Activity",
    },
    {
      element: "text",
      innerText: "You can schedule your activity to run once or at set intervals.",
    },
    {
      element: "text",
      innerText:
        "Whenever a scheduled activity runs, the generated content is sent to the ",
      inline: true,
    },
    {
      element: "link",
      name: "recent activities",
      innerText: " recent activities",
      inline: true,
      params: {
        status: "recent",
      },
    },
    {
      element: "text",
      innerText: " section.",
      inline: true,
    },
  ],
  "onboarding/company-and-website/suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company and Website",
    },
    {
      element: "text",
      innerText: "Suggested based on your email address.",
    },
  ],
  "onboarding/company-and-website/no-suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company and Website",
    },
    {
      element: "text",
      innerText:
        "This information will be used to tailor the assistant to your business.",
    },
  ],
  "onboarding/company-description-initial/suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company Description",
    },
    {
      element: "text",
      innerText: "Generated based on the website you provided",
    },
  ],
  "onboarding/company-description-initial/no-suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company Description",
    },
    {
      element: "text",
      innerText: "This information will be used to train your assistant",
    },
  ],
  "onboarding/competitor-websites/suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Competitor Websites",
    },
    {
      element: "text",
      innerText: "We found the following competitor websites",
    },
  ],
  "onboarding/competitor-websites/no-suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Competitor Websites",
    },
    {
      element: "text",
      innerText:
        "We could't find any competitors for your company. You can add some manually",
    },
  ],
  "onboarding/company-social-site-links/suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company Social Site Links",
    },
    {
      element: "text",
      innerText: "These social accounts were found for your company",
    },
  ],
  "onboarding/company-social-site-links/no-suggestions": [
    {
      element: "heading",
      innerText: "Onboarding: Company Social Site Links",
    },
    {
      element: "text",
      innerText:
        "We couldn't find any social accounts for your company. You can add them manually",
    },
  ],
};

<template>
  <MAFieldWrapper
    :type="type"
    :label="label"
    :value="value"
    :disabled="disabled"
    :required="required"
    :readonly="readonly"
    :placeholder="placeholder"
    :rules="rules"
  >
    <template v-slot="{ validator, handleBlur }">
      <InputText
        v-model="value"
        :type="type"
        :invalid="validator?.$dirty && validator?.$invalid"
        :disabled="disabled"
        :autofocus="autofocus"
        :placeholder="placeholder"
        @blur="onBlur(handleBlur)"
        class="w-full"
      />
    </template>
  </MAFieldWrapper>
</template>

<script setup lang="ts">
import MAFieldWrapper from "@/components/primevueComponents/MAFieldWrapper.vue";
import type { AnyFn } from "@vueuse/core";
import InputText from "primevue/inputtext";
import { ulid } from "ulid";

const props = withDefaults(
  defineProps<{
    type?: "email" | "text" | "phone" | "url" | "password";
    label?: string;
    modelValue?: string;
    disabled?: boolean;
    required?: boolean;
    readonly?: boolean;
    placeholder?: string;
    autofocus?: boolean;
    rules?: Record<string, any>;
  }>(),
  {
    id: ulid(),
    disabled: false,
    placeholder: "",
    modelValue: "",
    type: "text",
    rules: () => ({}),
  }
);

const emit = defineEmits(["update:modelValue", "blur"]);
const value = useVModel(props, "modelValue", emit);

function onBlur(cb?: AnyFn) {
  if (cb) cb();
  emit("blur");
}
</script>

import type { RouteRecordRaw } from "vue-router";

const {
  BRAIN,
  ASSISTANT_PLATFORM,
  ASSISTANT_WEBSITE,
  SINGLE_RESEARCH_RESULT,
  ASSISTANT_PLATFORM_DETAILS,
} = RouteNames;
export const assistantRoutes: RouteRecordRaw[] = [
  {
    name: BRAIN,
    path: "",
    component: () => import("@/views/AssistantView.vue"),
  },
  {
    path: "query",
    children: [
      {
        path: "",
        redirect: {
          name: BRAIN,
          query: {
            currentTab: "research",
          },
        },
      },
      {
        path: ":id",
        name: SINGLE_RESEARCH_RESULT,
        component: () => import("@/views/AssistantSingleResearch.vue"),
      },
    ],
  },
  {
    path: ":scope", //account,competitor
    redirect: () => ({ name: BRAIN, query: { currentTab: "about" } }),
    children: [
      {
        path: ":platform", //website,twitter,facebook,etc
        name: ASSISTANT_PLATFORM,
        children: [
          {
            path: "",
            name: ASSISTANT_WEBSITE,
            component: () => import("@/views/AssistantPlatformDetails.vue"),
          },
          {
            path: ":id",
            name: ASSISTANT_PLATFORM_DETAILS,
            component: () => import("@/views/AssistantPlatformDetails.vue"),
          },
        ],
      },
    ],
  },
];

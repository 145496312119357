import { type UnwrapRef } from "vue";

const featureFlags = computed(() => ({
  messagesUi:
    env.VITE_ENABLE_FEATURE__MESSAGES_UI === "1" &&
    env.VITE_ENABLE_FEATURE__INTEGRATION_EMAIL === "1",
  multiUser:
    env.VITE_ENABLE_FEATURE__ADD_ACCOUNT_USERS === "1" &&
    !!env.VITE_MULTIPLE_USER_ENABLED_PRICE_ID,
  freeTrialNoStripe: env.VITE_ENABLE_FEATURE__FREE_TRIAL_WITHOUT_STRIPE === "1",
  integrationFacebook: env.VITE_ENABLE_FEATURE__INTEGRATION_FACEBOOK === "1",
  integrationInstagram: env.VITE_ENABLE_FEATURE__INTEGRATION_INSTAGRAM === "1",
  integrationWordpress: env.VITE_ENABLE_FEATURE__INTEGRATION_WORDPRESS === "1",
  integrationTeams: env.VITE_ENABLE_FEATURE__INTEGRATION_TEAMS === "1",
  integrationEmail: env.VITE_ENABLE_FEATURE__INTEGRATION_EMAIL === "1",
  integrationZapier: env.VITE_ENABLE_FEATURE__INTEGRATION_ZAPIER === "1",
  integrationTwitter: env.VITE_ENABLE_FEATURE__INTEGRATION_TWITTER === "1",
  accountFilesUi: env.VITE_ENABLE_FEATURE__ACCOUNT_FILES_UI === "1",
  v2Wrapper: env.VITE_ENABLE_FEATURE__V2_APP_WRAPPER === "1",
  accountDeletion: env.VITE_ENABLE_FEATURE__ACCOUNT_DELETION === "1",
  welcomeTour: env.VITE_ENABLE_FEATURE__WELCOME_TOUR === "1",
  accountUserDeletion: env.VITE_ENABLE_FEATURE__ACCOUNT_USER_DELETION === "1",
}));

type FeatureFlags = keyof UnwrapRef<typeof featureFlags>;

export function hasFeatureFlag(feature: FeatureFlags[] | FeatureFlags) {
  const featureKeys = Object.keys(featureFlags.value) as FeatureFlags[];
  const features = Array.isArray(feature) ? feature : [feature];
  const filtered = features.filter(f => featureKeys.includes(f));
  if (filtered.length !== features.length) {
    // if there was incorrect passed in, fail it
    return false;
  }
  // make sure they are all enabled
  return filtered.every(f => featureFlags.value[f]);
}

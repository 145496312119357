import type { RouteRecordRaw } from "vue-router";

export const tenantRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: RouteNames.TENANT_HOME,
    redirect: () => ({ name: RouteNames.TENANT_ACCOUNTS }),
  },
  {
    name: RouteNames.TENANT_ACCOUNTS,
    path: "accounts",
    component: () => import("@/views/tenant/AccountsView.vue"),
    meta: {
      titleTag: "Accounts | Marketing Assistant",
    },
  },
  {
    name: RouteNames.TENANT_WELCOME,
    path: "welcome",
    component: () => import("@/views/tenant/TenantWelcome.vue"),
    meta: {
      titleTag: "Welcome | Marketing Assistant",
    },
  },
  {
    name: RouteNames.TENANT_SETTINGS,
    path: "settings",
    component: () => import("@/views/tenant/TenantSettings.vue"),
    children: [
      {
        name: RouteNames.TENANT_ACCOUNT_SETTINGS,
        path: "account",
        component: () => import("@/views/tenant/settings/AccountPanel.vue"),
        meta: {
          titleTag: "Account Settings | Marketing Assistant",
        },
      },
      {
        name: RouteNames.TENANT_ACCOUNT_USERS,
        path: "users",
        component: () => import("@/views/tenant/settings/AccountUsers.vue"),
        meta: {
          titleTag: "Users | Marketing Assistant",
        },
      },
      {
        path: "",
        name: RouteNames.TENANT_SETTINGS_REDIRECT,
        redirect: () => ({ name: RouteNames.TENANT_ACCOUNT_SETTINGS }),
      },
      {
        path: "/",
        name: RouteNames.TENANT_SETTINGS_REDIRECT2,
        redirect: () => ({ name: RouteNames.TENANT_ACCOUNT_SETTINGS }),
      },
    ],
  },
];
